import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueTheMask from "vue-the-mask";
import Vuelidate from 'vuelidate';

Vue.use(VueAxios, axios);
Vue.use(VueTheMask);
Vue.use(Vuelidate);

// components
import OrderForm from "./components/OrderForm.vue";
import ContactForm from "./components/ContactForm.vue";

const app = new Vue({
    el: '#app',
    components: {
        OrderForm,
        ContactForm
    }
});