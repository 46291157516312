<template>
    <div class="text-primary w-full xl:w-1/2 mx-auto">
        <div v-if="success">
            <div class="block px-8 py-4 border border-green-600 bg-green-100 text-lg text-center text-green-600">Wiadomość została wysłana</div>
        </div>
        <div v-else>
            <div :class="{ 'opacity-30': isSaving }">
                <div v-if="error" class="block px-8 py-3 border border-red-600 bg-red-100 text-lg text-center text-red-600 mb-4">{{ error }}</div>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-3">
                    <!-- first name -->
                    <div>
                        <input
                            v-model="form.first_name"
                            type="text"
                            placeholder="Imię *"
                            class="form-control"
                            :class="{ 'border-secondary focus:ring-red-200': $v.form.first_name.$dirty && $v.form.first_name.$invalid }"
                            required
                            @blur="$v.form.first_name.$touch()"
                            :disabled="isSaving"
                        />
                        <div class="h-5 text-xs leading-relaxed text-secondary">
                            {{ $v.form.first_name.$dirty && $v.form.first_name.$invalid ? "Popraw to pole" : "" }}
                        </div>
                    </div>
                    <!-- last name -->
                    <div>
                        <input
                            v-model="form.last_name"
                            type="text"
                            placeholder="Nazwisko *"
                            class="form-control"
                            :class="{ 'border-secondary focus:ring-red-200': $v.form.last_name.$dirty && $v.form.last_name.$invalid }"
                            required
                            @blur="$v.form.last_name.$touch()"
                            :disabled="isSaving"
                        />
                        <div class="h-5 text-xs leading-relaxed text-secondary">
                            {{ $v.form.last_name.$dirty && $v.form.last_name.$invalid ? "Popraw to pole" : "" }}
                        </div>
                    </div>
                    <!-- numer telefonu -->
                    <div>
                        <input
                            v-model="form.phone"
                            type="text"
                            placeholder="Telefon *"
                            class="form-control"
                            :class="{ 'border-secondary focus:ring-red-200': $v.form.phone.$dirty && $v.form.phone.$invalid }"
                            required
                            @blur="$v.form.phone.$touch()"
                            v-mask="'#########'"
                            :disabled="isSaving"
                        />
                        <div class="h-5 text-xs leading-relaxed text-secondary">
                            {{ $v.form.phone.$dirty && $v.form.phone.$invalid ? "Popraw to pole" : "" }}
                        </div>
                    </div>
                    <!-- email -->
                    <div>
                        <input
                            v-model="form.email"
                            type="text"
                            placeholder="E-mail *"
                            class="form-control"
                            :class="{ 'border-secondary focus:ring-red-200': $v.form.email.$dirty && $v.form.email.$invalid }"
                            required
                            @blur="$v.form.email.$touch()"
                            :disabled="isSaving"
                        />
                        <div class="h-5 text-xs leading-relaxed text-secondary">
                            {{ $v.form.email.$dirty && $v.form.email.$invalid ? "Popraw to pole" : "" }}
                        </div>
                    </div>
                </div>
                <!-- Description -->
                <div class="mt-4">
                    <textarea
                        v-model="form.description"
                        type="text"
                        placeholder="Treść wiadomości *"
                        class="form-control h-auto"
                        rows="5"
                        :class="{ 'border-secondary focus:ring-red-200': $v.form.description.$dirty && $v.form.description.$invalid }"
                        required
                        @blur="$v.form.description.$touch()"
                        :disabled="isSaving"
                    ></textarea>
                    <div class="h-5 text-xs leading-relaxed text-secondary">
                        {{ $v.form.description.$dirty && $v.form.description.$invalid ? "Popraw to pole" : "" }}
                    </div>
                </div>
                <div class="flex items-start mb-5">
                    <div class="text-secondary mr-2 text-xl">*</div>
                    <div class="text-sm">pola wymagane</div>
                </div>
                <div class="mb-4">
                    <label for="formPrivacy" class="inline-flex items-center cursor-pointer group">
                        <input
                            :disabled="isSaving"
                            class="w-5 h-5 rounded mr-3 border-warmgray-400 text-secondary focus:ring-2 focus:ring-secondary focus:ring-opacity-50"
                            id="formPrivacy"
                            v-model="form.privacy"
                            type="checkbox"
                            @click="$v.form.privacy.$touch()"
                        />
                        <span
                            :class="{ 'text-secondary': $v.form.privacy.$dirty && $v.form.privacy.$invalid }"
                            class="group-hover:text-gray-500 transition duration-500 select-none"
                            >Wyrażam zgodę na przetwarzanie moich danych osobowych.</span
                        >
                    </label>
                    <div class="h-5 text-xs leading-relaxed text-secondary">
                        {{ $v.form.privacy.$dirty && $v.form.privacy.$invalid ? "Zaznacz to pole" : "" }}
                    </div>
                </div>
            </div>
            <input v-model="form.g_recaptcha_response" type="hidden" class="recaptcha_response_form" />
            <button
                class="flex items-center justify-center w-full px-4 py-3 bg-warmgray-600 text-white text-center font-bold tracking-wide transition duration-500 focus:outline-none hover:bg-warmgray-700 focus:ring-2 focus:ring-warmgray-700 focus:ring-opacity-50 mb-24"
                :class="isSaving ? 'cursor-default' : 'cursor-pointer'"
                @click="submit"
            >
                <svg v-if="isSaving" class="animate-spin -ml-1 h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>
                <span v-if="!isSaving">wyślij</span>
            </button>
        </div>
    </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";

export default {
    name: "ContactForm",
    props: ["sitekey"],
    data() {
        return {
            form: {
                first_name: "",
                last_name: "",
                phone: "",
                email: "",
                privacy: 0,
                g_recaptcha_response: "",
            },
            isSaving: false,
            success: false,
            error: "",
        };
    },
    methods: {
        async submit() {
            if (!this.isSaving) {
                this.error = "";
                this.isSaving = true;
                this.$v.$touch();
                if (!this.$v.$invalid) {
                    const formData = new FormData();
                    Object.entries(this.form).forEach(([key, value]) => {
                        formData.append(key, value);
                    });
                    const { data } = await this.$http.post("Sendmail", formData, {
                        headers: {
                            "content-type": "multipart/form-data",
                        },
                    });
                    if (!data.isError) {
                        this.success = true;
                        this.form.first_name = "";
                        this.form.last_name = "";
                        this.form.phone = "";
                        this.form.email = "";
                        this.form.privacy = 0;
                        this.$v.$reset();
                    } else {
                        this.error = data.messages;
                    }
                }
                this.isSaving = false;
            }
        },
    },
    validations: {
        form: {
            first_name: {
                required,
            },
            last_name: {
                required,
            },
            phone: {
                required,
            },
            email: {
                required,
                email,
            },
            description: {
                required,
            },
            privacy: {
                privacy(value) {
                    return value == 1 ? true : false;
                },
            },
        },
    },
    created() {
        const that = this;
        grecaptcha.ready(function () {
            grecaptcha
                .execute(`${that.sitekey}`, {
                    action: "homepage",
                })
                .then(function (token) {
                    that.form.g_recaptcha_response = token;
                });
        });
    },
};
</script>

<style>
</style>