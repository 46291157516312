<template>
    <div class="text-primary">
        <div class="relative">
            <div v-if="isSaving" class="absolute top-0 left-0 w-full h-full bg-white opacity-40"></div>
            <div class="font-bold text-xl mb-6">Dane do zamówienia</div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-3">
                <!-- first name -->
                <div>
                    <input
                        v-model="form.first_name"
                        type="text"
                        placeholder="Imię *"
                        class="form-control"
                        :class="{ 'border-secondary focus:ring-red-200': $v.form.first_name.$dirty && $v.form.first_name.$invalid }"
                        required
                        @blur="$v.form.first_name.$touch()"
                        :disabled="isSaving"
                    />
                    <div class="h-5 text-xs leading-relaxed text-secondary">
                        {{ $v.form.first_name.$dirty && $v.form.first_name.$invalid ? "Popraw to pole" : "" }}
                    </div>
                </div>
                <!-- last name -->
                <div>
                    <input
                        v-model="form.last_name"
                        type="text"
                        placeholder="Nazwisko *"
                        class="form-control"
                        :class="{ 'border-secondary focus:ring-red-200': $v.form.last_name.$dirty && $v.form.last_name.$invalid }"
                        required
                        @blur="$v.form.last_name.$touch()"
                        :disabled="isSaving"
                    />
                    <div class="h-5 text-xs leading-relaxed text-secondary">
                        {{ $v.form.last_name.$dirty && $v.form.last_name.$invalid ? "Popraw to pole" : "" }}
                    </div>
                </div>
                <!-- company name -->
                <div>
                    <input
                        v-model="form.company_name"
                        type="text"
                        placeholder="Nazwa firmy"
                        class="form-control"
                        :class="{ 'border-secondary focus:ring-red-200': $v.form.company_name.$dirty && $v.form.company_name.$invalid }"
                        required
                        @blur="$v.form.company_name.$touch()"
                        :disabled="isSaving"
                    />
                    <div class="h-5 text-xs leading-relaxed text-secondary">
                        {{ $v.form.company_name.$dirty && $v.form.company_name.$invalid ? "Popraw to pole" : "" }}
                    </div>
                </div>
                <!-- nip -->
                <div>
                    <input
                        v-model="form.nip"
                        type="text"
                        placeholder="NIP"
                        class="form-control"
                        :class="{ 'border-secondary focus:ring-red-200': $v.form.nip.$dirty && $v.form.nip.$invalid }"
                        required
                        @blur="$v.form.nip.$touch()"
                        v-mask="'##########'"
                        :disabled="isSaving"
                    />
                    <div class="h-5 text-xs leading-relaxed text-secondary">{{ $v.form.nip.$dirty && $v.form.nip.$invalid ? "Podaj 10-cyfrowy NIP" : "" }}</div>
                </div>
                <!-- street -->
                <div>
                    <input
                        v-model="form.street"
                        type="text"
                        placeholder="Ulica *"
                        class="form-control"
                        :class="{ 'border-secondary focus:ring-red-200': $v.form.street.$dirty && $v.form.street.$invalid }"
                        required
                        @blur="$v.form.street.$touch()"
                        :disabled="isSaving"
                    />
                    <div class="h-5 text-xs leading-relaxed text-secondary">{{ $v.form.street.$dirty && $v.form.street.$invalid ? "Popraw to pole" : "" }}</div>
                </div>
                <!-- home number -->
                <div>
                    <input
                        v-model="form.home_number"
                        type="text"
                        placeholder="Numer domu / mieszkania *"
                        class="form-control"
                        :class="{ 'border-secondary focus:ring-red-200': $v.form.home_number.$dirty && $v.form.home_number.$invalid }"
                        required
                        @blur="$v.form.home_number.$touch()"
                        :disabled="isSaving"
                    />
                    <div class="h-5 text-xs leading-relaxed text-secondary">
                        {{ $v.form.home_number.$dirty && $v.form.home_number.$invalid ? "Popraw to pole" : "" }}
                    </div>
                </div>
                <!-- post code -->
                <div>
                    <input
                        v-model="form.post_code"
                        type="text"
                        placeholder="Kod pocztowy *"
                        class="form-control"
                        :class="{ 'border-secondary focus:ring-red-200': $v.form.post_code.$dirty && $v.form.post_code.$invalid }"
                        required
                        @blur="$v.form.post_code.$touch()"
                        v-mask="'##-###'"
                        :disabled="isSaving"
                    />
                    <div class="h-5 text-xs leading-relaxed text-secondary">
                        {{ $v.form.post_code.$dirty && $v.form.post_code.$invalid ? "Popraw to pole" : "" }}
                    </div>
                </div>
                <!-- miasto -->
                <div>
                    <input
                        v-model="form.city"
                        type="text"
                        placeholder="Miasto *"
                        class="form-control"
                        :class="{ 'border-secondary focus:ring-red-200': $v.form.city.$dirty && $v.form.city.$invalid }"
                        required
                        @blur="$v.form.city.$touch()"
                        :disabled="isSaving"
                    />
                    <div class="h-5 text-xs leading-relaxed text-secondary">{{ $v.form.city.$dirty && $v.form.city.$invalid ? "Popraw to pole" : "" }}</div>
                </div>
                <!-- numer telefonu -->
                <div>
                    <input
                        v-model="form.phone"
                        type="text"
                        placeholder="Telefon *"
                        class="form-control"
                        :class="{ 'border-secondary focus:ring-red-200': $v.form.phone.$dirty && $v.form.phone.$invalid }"
                        required
                        @blur="$v.form.phone.$touch()"
                        v-mask="'#########'"
                        :disabled="isSaving"
                    />
                    <div class="h-5 text-xs leading-relaxed text-secondary">{{ $v.form.phone.$dirty && $v.form.phone.$invalid ? "Popraw to pole" : "" }}</div>
                </div>
                <!-- email -->
                <div>
                    <input
                        v-model="form.email"
                        type="text"
                        placeholder="E-mail *"
                        class="form-control"
                        :class="{ 'border-secondary focus:ring-red-200': $v.form.email.$dirty && $v.form.email.$invalid }"
                        required
                        @blur="$v.form.email.$touch()"
                        :disabled="isSaving"
                    />
                    <div class="h-5 text-xs leading-relaxed text-secondary">{{ $v.form.email.$dirty && $v.form.email.$invalid ? "Popraw to pole" : "" }}</div>
                </div>
            </div>
            <div class="flex items-start mb-5">
                <div class="text-secondary mr-2 text-xl">*</div>
                <div class="text-sm">pola wymagane</div>
            </div>
            <div class="mb-12">
                <label for="formPrivacy" class="inline-flex items-center cursor-pointer group">
                    <input
                        :disabled="isSaving"
                        class="w-5 h-5 rounded mr-3 border-warmgray-400 text-secondary focus:ring-2 focus:ring-secondary focus:ring-opacity-50"
                        id="formPrivacy"
                        v-model="form.privacy"
                        type="checkbox"
                        @click="$v.form.privacy.$touch()"
                    />
                    <span
                        :class="{ 'text-secondary': $v.form.privacy.$dirty && $v.form.privacy.$invalid }"
                        class="group-hover:text-gray-500 transition duration-500 select-none"
                        >Wyrażam zgodę na przetwarzanie moich danych osobowych.</span
                    >
                </label>
                <div class="h-5 text-xs leading-relaxed text-secondary">{{ $v.form.privacy.$dirty && $v.form.privacy.$invalid ? "Zaznacz to pole" : "" }}</div>
            </div>

            <div class="font-bold text-xl mb-6">Twoje zamówienie</div>
            <table class="table-auto w-full text-sm md:text-base mb-10">
                <thead>
                    <tr class="border-b border-secondary">
                        <th class="text-left pr-2">Produkt</th>
                        <th class="text-center px-2">Ilość</th>
                        <th class="text-right pl-2">Razem</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="text-left py-3 pr-2 font-light">Typologia osobowości w praktyce</td>
                        <td class="text-center py-3 px-2 font-light">
                            <div class="inline-flex items-center">
                                <div
                                    class="mdi mdi-minus-circle-outline text-lg text-warmgray-400 transition duration-500 hover:text-warmgray-800 cursor-pointer"
                                    @click="removeQuantity"
                                ></div>
                                <div class="mx-3">{{ form.quantity }}</div>
                                <div
                                    class="mdi mdi-plus-circle-outline text-lg text-warmgray-400 transition duration-500 hover:text-warmgray-800 cursor-pointer"
                                    @click="addQuantity"
                                ></div>
                            </div>
                        </td>
                        <td class="text-right py-3 pl-2 font-light">{{ total }} zł.</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div v-if="error" class="block px-8 py-3 border border-red-600 bg-red-100 text-base text-center text-red-600 mb-4">{{ error }}</div>

        <button
            class="flex items-center justify-center w-full px-4 py-3 bg-warmgray-600 text-white text-center font-bold tracking-wide transition duration-500 focus:outline-none hover:bg-warmgray-700 focus:ring-2 focus:ring-warmgray-700 focus:ring-opacity-50"
            :class="isSaving ? 'cursor-default' : 'cursor-pointer'"
            @click="submit"
        >
            <svg v-if="isSaving" class="animate-spin -ml-1 mr-3 h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
            </svg>
            <span v-if="!isSaving">Zamawiam i płacę</span>
        </button>
    </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";

export default {
    name: "OrderForm",
    props: ["price", "sitekey"],
    data() {
        return {
            form: {
                first_name: "",
                last_name: "",
                company_name: "",
                nip: "",
                street: "",
                home_number: "",
                post_code: "",
                city: "",
                phone: "",
                email: "",
                privacy: 0,
                quantity: 1,
                price: 0,
                total: 0,
                g_recaptcha_response: "",
            },
            isSaving: false,
            error: "",
        };
    },
    computed: {
        total() {
            return this.form.total.toFixed(2).replace(".", ",");
        },
    },
    methods: {
        async submit() {
            this.error = "";
            this.isSaving = true;
            this.$v.$touch();
            if (!this.$v.$invalid) {
                const formData = new FormData();
                Object.entries(this.form).forEach(([key, value]) => {
                    formData.append(key, value);
                });
                const { data } = await this.$http.post("order", formData, {
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                });
                if (!data.isError) {
                    if (data.token && data.redirect) {
                        window.location.replace(data.redirect);
                    } else {
                        this.error = "Brak tokenu";
                    }
                } else {
                    this.error = data.messages;
                }
            } else {
                this.error = "Popraw błędy w formularzu"
            }
            this.isSaving = false;
        },
        addQuantity() {
            if (!this.isSaving) {
                this.form.quantity += 1;
                this.calcPrice();
            }
        },
        removeQuantity() {
            if (this.form.quantity > 1 && !this.isSaving) {
                this.form.quantity -= 1;
                this.calcPrice();
            }
        },
        calcPrice() {
            this.form.total = parseFloat(this.form.price) * parseInt(this.form.quantity);
        },
    },
    validations: {
        form: {
            first_name: {
                required,
            },
            last_name: {
                required,
            },
            company_name: {},
            nip: {
                nip(value) {
                    return !value || /^[0-9]{10}$/.test(value);
                },
            },
            street: {
                required,
            },
            home_number: {
                required,
            },
            post_code: {
                required,
                postCode(value) {
                    return /^[0-9]{2}[-]{1}[0-9]{3}$/.test(value);
                },
            },
            city: {
                required,
            },
            phone: {
                required,
            },
            email: {
                required,
                email,
            },
            privacy: {
                privacy(value) {
                    return value == 1 ? true : false;
                },
            },
        },
    },
    created() {
        this.form.price = parseFloat(this.price);
        this.form.total = parseFloat(this.price) * this.form.quantity;
        const that = this;
        grecaptcha.ready(function () {
            grecaptcha
                .execute(`${that.sitekey}`, {
                    action: "homepage",
                })
                .then(function (token) {
                    that.form.g_recaptcha_response = token;
                });
        });
    },
};
</script>

<style>
</style>